svg g {
  fill: rgba(255, 255, 255, 0.2);
}

.ae #ae {
  fill: white;
}

.af #af {
  fill: white;
}

.al #al {
  fill: white;
}

.am #am {
  fill: white;
}

.ao #ao {
  fill: white;
}

.ar #ar {
  fill: white;
}

.at #at {
  fill: white;
}

.au #au {
  fill: white;
}

.az #az {
  fill: white;
}

.ba #ba {
  fill: white;
}

.bd #bd {
  fill: white;
}

.be #be {
  fill: white;
}

.bf #bf {
  fill: white;
}

.bg #bg {
  fill: white;
}

.bi #bi {
  fill: white;
}

.bj #bj {
  fill: white;
}

.bn #bn {
  fill: white;
}

.bo #bo {
  fill: white;
}

.br #br {
  fill: white;
}

.bs #bs {
  fill: white;
}

.bt #bt {
  fill: white;
}

.bw #bw {
  fill: white;
}

.by #by {
  fill: white;
}

.bz #bz {
  fill: white;
}

.ca #ca {
  fill: white;
}

.cd #cd {
  fill: white;
}

.cf #cf {
  fill: white;
}

.cg #cg {
  fill: white;
}

.ch #ch {
  fill: white;
}

.ci #ci {
  fill: white;
}

.cl #cl {
  fill: white;
}

.cm #cm {
  fill: white;
}

.cn #cn {
  fill: white;
}

.co #co {
  fill: white;
}

.cr #cr {
  fill: white;
}

.cu #cu {
  fill: white;
}

.cy #cy {
  fill: white;
}

.cz #cz {
  fill: white;
}

.de #de {
  fill: white;
}

.dj #dj {
  fill: white;
}

.dk #dk {
  fill: white;
}

.do #do {
  fill: white;
}

.dz #dz {
  fill: white;
}

.ec #ec {
  fill: white;
}

.ee #ee {
  fill: white;
}

.eg #eg {
  fill: white;
}

.eh #eh {
  fill: white;
}

.er #er {
  fill: white;
}

.es #es {
  fill: white;
}

.et #et {
  fill: white;
}

.fi #fi {
  fill: white;
}

.fj #fj {
  fill: white;
}

.fk #fk {
  fill: white;
}

.fr #fr {
  fill: white;
}

.ga #ga {
  fill: white;
}

.gb #gb {
  fill: white;
}

.ge #ge {
  fill: white;
}

.gh #gh {
  fill: white;
}

.gl #gl {
  fill: white;
}

.gm #gm {
  fill: white;
}

.gn #gn {
  fill: white;
}

.gq #gq {
  fill: white;
}

.gr #gr {
  fill: white;
}

.gt #gt {
  fill: white;
}

.gw #gw {
  fill: white;
}

.gy #gy {
  fill: white;
}

.hn #hn {
  fill: white;
}

.hr #hr {
  fill: white;
}

.ht #ht {
  fill: white;
}

.hu #hu {
  fill: white;
}

.id #id {
  fill: white;
}

.ie #ie {
  fill: white;
}

.il #il {
  fill: white;
}

.in #in {
  fill: white;
}

.iq #iq {
  fill: white;
}

.ir #ir {
  fill: white;
}

.is #is {
  fill: white;
}

.it #it {
  fill: white;
}

.jm #jm {
  fill: white;
}

.jo #jo {
  fill: white;
}

.jp #jp {
  fill: white;
}

.ke #ke {
  fill: white;
}

.kg #kg {
  fill: white;
}

.kh #kh {
  fill: white;
}

.kp #kp {
  fill: white;
}

.kr #kr {
  fill: white;
}

.kw #kw {
  fill: white;
}

.kz #kz {
  fill: white;
}

.la #la {
  fill: white;
}

.lb #lb {
  fill: white;
}

.lk #lk {
  fill: white;
}

.lr #lr {
  fill: white;
}

.ls #ls {
  fill: white;
}

.lt #lt {
  fill: white;
}

.lu #lu {
  fill: white;
}

.lv #lv {
  fill: white;
}

.ly #ly {
  fill: white;
}

.ma #ma {
  fill: white;
}

.md #md {
  fill: white;
}

.me #me {
  fill: white;
}

.mg #mg {
  fill: white;
}

.mk #mk {
  fill: white;
}

.ml #ml {
  fill: white;
}

.mm #mm {
  fill: white;
}

.mn #mn {
  fill: white;
}

.mr #mr {
  fill: white;
}

.mw #mw {
  fill: white;
}

.mx #mx {
  fill: white;
}

.my #my {
  fill: white;
}

.mz #mz {
  fill: white;
}

.na #na {
  fill: white;
}

.nc #nc {
  fill: white;
}

.ne #ne {
  fill: white;
}

.ng #ng {
  fill: white;
}

.ni #ni {
  fill: white;
}

.nl #nl {
  fill: white;
}

.no #no {
  fill: white;
}

.np #np {
  fill: white;
}

.nz #nz {
  fill: white;
}

.om #om {
  fill: white;
}

.pa #pa {
  fill: white;
}

.pe #pe {
  fill: white;
}

.pg #pg {
  fill: white;
}

.ph #ph {
  fill: white;
}

.pk #pk {
  fill: white;
}

.pl #pl {
  fill: white;
}

.pr #pr {
  fill: white;
}

.ps #ps {
  fill: white;
}

.pt #pt {
  fill: white;
}

.py #py {
  fill: white;
}

.qa #qa {
  fill: white;
}

.ro #ro {
  fill: white;
}

.rs #rs {
  fill: white;
}

.ru #ru {
  fill: white;
}

.rw #rw {
  fill: white;
}

.sa #sa {
  fill: white;
}

.sb #sb {
  fill: white;
}

.sd #sd {
  fill: white;
}

.se #se {
  fill: white;
}

.sg #sg {
  fill: white;
}

.si #si {
  fill: white;
}

.sk #sk {
  fill: white;
}

.sl #sl {
  fill: white;
}

.sn #sn {
  fill: white;
}

.so #so {
  fill: white;
}

.sr #sr {
  fill: white;
}

.ss #ss {
  fill: white;
}

.sv #sv {
  fill: white;
}

.sy #sy {
  fill: white;
}

.sz #sz {
  fill: white;
}

.td #td {
  fill: white;
}

.tf #tf {
  fill: white;
}

.tg #tg {
  fill: white;
}

.th #th {
  fill: white;
}

.tj #tj {
  fill: white;
}

.tl #tl {
  fill: white;
}

.tm #tm {
  fill: white;
}

.tn #tn {
  fill: white;
}

.tr #tr {
  fill: white;
}

.tt #tt {
  fill: white;
}

.tw #tw {
  fill: white;
}

.tz #tz {
  fill: white;
}

.ua #ua {
  fill: white;
}

.ug #ug {
  fill: white;
}

.us #us {
  fill: white;
}

.uy #uy {
  fill: white;
}

.uz #uz {
  fill: white;
}

.ve #ve {
  fill: white;
}

.vn #vn {
  fill: white;
}

.vu #vu {
  fill: white;
}

.ye #ye {
  fill: white;
}

.za #za {
  fill: white;
}

.zm #zm {
  fill: white;
}

.zw #zw {
  fill: white;
}
